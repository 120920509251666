<template>
  <div class="form--group row">
    <div class="col-12 col-lg-3">
      <label>
        <span>{{ $t('listing.facility') }}</span>
      </label>
    </div>
    <div class="col-12 col-lg-6">
      <facility-with-unit-field
        v-for="facility in facilitiesWithUnit"
        :key="facility.id"
        :name="facility.name"
        :selected="facility.selected"
        :id="facility.id"
        :unitName="facility.uom_name"
        :amount="facility.amount"
        ref="facilityWithUnitRef"
        :readOnly="readOnly"
      />
      <div class="form--group row">
        <facility-without-unit-field
          v-for="facility in facilitiesWithoutUnit"
          :key="facility.id"
          :name="facility.name"
          :selected="facility.selected"
          :id="facility.id"
          :readOnly="readOnly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const FacilityWithUnitField = () =>
  import('@/components/data-entry/listing/form/facility/facility-with-unit-field.vue');
const FacilityWithoutUnitField = () =>
  import('@/components/data-entry/listing/form/facility/facility-without-unit-field.vue');

export default {
  name: 'facilities-section',
  props: ['readOnly'],
  components: {
    FacilityWithUnitField,
    FacilityWithoutUnitField,
    // FacilityWithoutUnit,
  },
  computed: {
    ...mapGetters({
      facilitiesWithUnit: 'listing/form/facilitiesWithUnit',
      facilitiesWithoutUnit: 'listing/form/facilitiesWithoutUnit',
    }),
  },
  methods: {
    async validate() {
      // console.log('REFS FACILITY WITH UNIT: ', this.$refs.facilityWithUnitRef);
      if (this.$refs.facilityWithUnitRef) {
        const facilityValidation = this.$refs.facilityWithUnitRef.map(function (form) {
          return form.$validate();
        });
        const results = await Promise.all(facilityValidation);
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      } else {
        return true;
      }
      // return Promise.all(validationArray).then(function(results) {
      //   return (
      //     results.filter(function(result) {
      //       return !result;
      //     }).length === 0
      //   );
      // });
    },
  },
};
</script>

<style scoped></style>
